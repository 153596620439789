@font-face {
  src: url(/static/fonts/LcdFont.ttf);
  font-family: lcd;
}


/* =============================   SITE   =============================== */

html {
  z-index: 3;
  background: black;
}

canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height:100%;
  /* background: radial-gradient(circle, transparent 0%, black 100%); */
}


@media only screen and (orientation:portrait) {
  body {
    height: 150vw;
    width: 100vw;
    transform: rotate(90deg);
  }
}



.scrollTarget {
  position: absolute;
  height: 3000vh;
  width: 100px;
  top: 0;
  z-index: 0;
}




main {
  width: 100vw;
  color: rgb(255, 255, 255);
  z-index: 99;
  /* position: fixed; */
  width: 100%;
  /* overflow: hidden; */
  /* margin: 0px auto;
  padding: 120px 0px; */
  letter-spacing: 4px;
  text-align: center;
  display: grid;
  /* grid-template-columns: repeat(12, 1fr); */
}


.mail {
  font-family: lcd;
  font-size: 15px;
  position: absolute;
  top: 2980vh;
  left: 50%;
  color: white;
  transform: translate(-50%, 100%);
  text-decoration: underline;
}

a {
  font-family: lcd;
  /* font-size: 20px; */
  color: white;
  text-decoration: none;
}



/* =============================   LOADING BAR   =============================== */




.loading-bar
{
  position: fixed;
  top: 0%;
  width: 100%;
  height: 100%;
  background: rgb(255, 0, 0);
  transform: scaleX(0);
  transform-origin: top left;
  transition: transform 0.5s;
  will-change: transform;
}

.loading-bar.ended
{
  transform-origin: top right;
  transition: transform 1.5s ease-in-out;
}



/* =============================   LOGO LOADING   =============================== */



@keyframes load {
  0%{
      opacity: 0.08;
/*         font-size: 10px; */
/* 				font-weight: 400; */
      filter: blur(5px);
      letter-spacing: 3px;
      }
  100%{
/*         opacity: 1; */
/*         font-size: 12px; */
/* 				font-weight:600; */
/* 				filter: blur(0); */
      }
}

.animate1 {
position: fixed;
top: 35vh;
left: 49.8%;
font-size: 30px;
font-family: lcd;
letter-spacing: 2px;
color: white;
justify-content: center;
align-items: center;
/* margin: auto; */
/* 	width: 350px; */
/* 	font-size:26px; */
animation: load 1.2s infinite 0s ease-in-out;
animation-direction: alternate;
text-shadow: 0 0 1px white;
transform: translate(-50%, -50%);
}


.animate2 {
  position: fixed;
  top: 56vh;
  left: 50%;
  font-size: 30px;
  font-family: lcd;
  letter-spacing: 2px;
  color: white;
  justify-content: center;
  align-items: center;
  height:100px;
  /* margin: auto; */
  /* 	width: 350px; */
  /* 	font-size:26px; */
  animation: load 1.2s infinite 0s ease-in-out;
  animation-direction: alternate;
  text-shadow: 0 0 1px white;
  transform: translate(-50%, -50%);
  }



/* =============================   on/off AUDIO toggle   =============================== */


.label {
  position: fixed;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: #394a56;
}

.label-text {
  margin-left: 16px;
  position: fixed;
  font-family: lcd;
}

.toggle {
  isolation: isolate;
  position: relative;
  height: 30px;
  width: 60px;
  border-radius: 15px;
  overflow: hidden;

}

.toggle-state {
  display: none;
}

.indicator {
  height: 100%;
  width: 200%;
  background: #ecf0f3;
  border-radius: 15px;
  transform: translate3d(-75%, 0, 0);
  transition: transform 0.4s cubic-bezier(0.85, 0.05, 0.18, 1.35);
  box-shadow:
    -8px -4px 8px 0px #ffffff,
    8px 4px 12px 0px #d1d9e6;
}

.toggle-state:checked ~ .indicator {
  transform: translate3d(25%, 0, 0);
}




/* =============================   PROGRESS BARS   =============================== */




.progress-bar {
	display: block;
	width: 100%;
	height: 4px;
	position: fixed;
	z-index: 50;
	background: linear-gradient(to right, #ffffff var(--scroll), transparent 0);
	background-repeat: no-repeat;
}

	.horizontal.top {
		top: 0;
		left: 0;
	}
	
	.horizontal.bottom {
		top: auto;
		bottom: 0;
		background: linear-gradient(to left, #ffffff var(--scroll), transparent 0);
	}
	
	.vertical.left {
		width: 4px;
		height: 100vh;
		top: 0;
		left: 0;
		background: linear-gradient(to bottom, #ffffff var(--scroll), transparent 0);
	}
	
	.vertical.right {
		width: 4px;
		height: 100vh;
		top: 0;
		right: 0;
		background: linear-gradient(to top, #ffffff var(--scroll), transparent 0);
	}


@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap');






/* =============================   TOOLTIPS   =============================== */



.point .label1
{
    position: absolute;
    top: 2930vh;
    left: 25%;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #00000077;
    border: 1px solid #ffffff77;
    color: #ffffff;
    font-family: Helvetica, Arial, sans-serif;
    text-align: center;
    line-height: 40px;
    font-weight: 100;
    font-size: 14px;
    cursor: pointer;
    transform: scale(1, 1);
    transition: transform 0.3s;
    transform: translate(-50%, 0%);
}

.point .label2
{
    position: absolute;
    top: 2925vh;
    left: 50%;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #00000077;
    border: 1px solid #ffffff77;
    color: #ffffff;
    font-family: Helvetica, Arial, sans-serif;
    text-align: center;
    line-height: 40px;
    font-weight: 100;
    font-size: 14px;
    cursor: pointer;
    transform: scale(1, 1);
    transition: transform 0.3s;
    transform: translate(-50%, 0%);
}

.point .label3
{
    position: absolute;
    top: 2930vh;
    left: 75%;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #00000077;
    border: 1px solid #ffffff77;
    color: #ffffff;
    font-family: Helvetica, Arial, sans-serif;
    text-align: center;
    line-height: 40px;
    font-weight: 100;
    font-size: 14px;
    cursor: pointer;
    transform: scale(1, 1);
    transition: transform 0.3s;
    transform: translate(-50%, 0%);
}

.point .text1
{
    position: absolute;
    top: 2940vh;
    left: 25%;
    width: 200px;
    padding: 20px;
    border-radius: 4px;
    background: #00000077;
    border: 1px solid #ffffff11;
    color: #ffffff;
    line-height: 1.3em;
    /* font-family: Helvetica, Arial, sans-serif; */
    font-family: lcd;
    text-align: center;
    font-weight: 100;
    font-size: 14px;
    opacity: 0.05;
    transition: opacity 0.3s;
    /* pointer-events: none; */
    transform: translate(-50%, 0%);
}

.point .text2
{
    position: absolute;
    top: 2935vh;
    left: 50%;
    width: 200px;
    padding: 20px;
    border-radius: 4px;
    background: #00000077;
    border: 1px solid #ffffff11;
    color: #ffffff;
    line-height: 1.3em;
    /* font-family: Helvetica, Arial, sans-serif; */
    font-family: lcd;
    text-align: center;
    font-weight: 100;
    font-size: 14px;
    opacity: 0.05;
    transition: opacity 0.3s;
    /* pointer-events: none; */
    transform: translate(-50%, 0%);
}

.point .text3
{
    position: absolute;
    top: 2940vh;
    left: 75%;
    width: 200px;
    padding: 20px;
    border-radius: 4px;
    background: #00000077;
    border: 1px solid #ffffff11;
    color: #ffffff;
    line-height: 1.3em;
    /* font-family: Helvetica, Arial, sans-serif; */
    font-family: lcd;
    text-align: center;
    text-decoration: none;
    font-weight: 100;
    font-size: 14px;
    opacity: 0.05;
    transition: opacity 0.3s;
    /* pointer-events: none; */
    transform: translate(-50%, 0%);
}

.point:hover .text1
{
    opacity: 1;
}

.point:hover .text2
{
    opacity: 1;
}

.point:hover .text3
{
    opacity: 1;
}

.point.visible .label1
{
    transform: scale(1, 1);
}

.point.visible .label2
{
    transform: scale(1, 1);
}

.point.visible .label3
{
    transform: scale(1, 1);
}



/* =============================   INTRO   =============================== */




.startbutton {
  position: fixed;
  top: 50%;
  left: 50%;
  font-family: lcd;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
  font-weight: 100;
  font-size: 14px;
  cursor: pointer;
  transform: scale(1, 1);
  transform: translate(-50%, -50%);
  z-index: 1;
}


button, button::after {
width: 380px;
height: 86px;
font-size: 36px;
font-family: lcd;
background: linear-gradient(45deg, transparent 5%, #ff0000 5%);
border: 0;
color: #fff;
letter-spacing: 3px;
line-height: 88px;
box-shadow: 6px 0px 0px #ffffff;
outline: transparent;
position: relative;
cursor: pointer;
opacity: 1;
}

button::after {
--slice-0: inset(50% 50% 50% 50%);
--slice-1: inset(80% -6px 0 0);
--slice-2: inset(50% -6px 30% 0);
--slice-3: inset(10% -6px 85% 0);
--slice-4: inset(40% -6px 43% 0);
--slice-5: inset(80% -6px 5% 0);

content: 'OUTATIME';
display: block;
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #FF013C 5%);
text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
clip-path: var(--slice-0);
}

button:hover::after {
animation: 1s glitch;
animation-timing-function: steps(2, end);
}

@keyframes glitch {
0% {
  clip-path: var(--slice-1);
  transform: translate(-20px, -10px);
}
10% {
  clip-path: var(--slice-3);
  transform: translate(10px, 10px);
}
20% {
  clip-path: var(--slice-1);
  transform: translate(-10px, 10px);
}
30% {
  clip-path: var(--slice-3);
  transform: translate(0px, 5px);
}
40% {
  clip-path: var(--slice-2);
  transform: translate(-5px, 0px);
}
50% {
  clip-path: var(--slice-3);
  transform: translate(5px, 0px);
}
60% {
  clip-path: var(--slice-4);
  transform: translate(5px, 10px);
}
70% {
  clip-path: var(--slice-2);
  transform: translate(-10px, 10px);
}
80% {
  clip-path: var(--slice-5);
  transform: translate(20px, -10px);
}
90% {
  clip-path: var(--slice-1);
  transform: translate(-10px, 0px);
}
100% {
  clip-path: var(--slice-1);
  transform: translate(0);
}
}



/* =============================   DEVICE ORIENTATION OVERLAY   =============================== */



@media only screen and (orientation: portrait) {
  .pt-overlay {
    display: flex;
    position: fixed;
    height: 100%;
    width: 150%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: black;
    background-size: 5000%;
    margin-top: 0%;
    margin-left: 0%;
    transform: scale(1, 1);
    /* transform: translate(-50%, -50%); */
    transform: rotate(-90deg);
    z-index: 2;
  }
  
  .phone {
    height: 50px;
    width: 100px;
    border: 3px solid white;
    border-radius: 10px;
    animation: rotate 1.5s ease-in-out infinite alternate;
    /* transform: translate(-50%, -50%); */
    /* display: none; */
  }
  
  .message {
    color: white;
    font-family: lcd;
    font-size: 1em;
    margin-top: 40px;
    /* transform: rotate(-90deg); */
    /* display: none; */
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0deg)
    }
    50% {
      transform: rotate(-90deg)
    }
    100% {
      transform: rotate(-90deg)
    }
  }
}